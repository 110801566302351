import React, { memo, useEffect, useState } from "react";
import "./index.scss";
import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { reqGetUserInfo } from "../../api";
import { useGlobalStore } from "../../store";
const Login = () => {
  const navigate = useNavigate();
  const [msgApi, msgContext] = message.useMessage();
  const [psd, setPwd] = useState("");
  const changeCode = useGlobalStore((store) => store.changeCode);
  useEffect(() => {
    const val = window.localStorage.getItem("psd");
    if (val) {
      setPwd(val);
    }
  }, []);

  const submit = async () => {
    try {
      if (!psd) {
        msgApi.error("请输入验证码");
        return;
      }
      const res = await reqGetUserInfo({
        password: psd,
      });
      const { code, data, message } = res;
      // console.log(res);
      if (code !== 200) {
        msgApi.error(message || "请求失败！");
        return;
      }
      window.localStorage.setItem("psd", psd);
      changeCode(psd, data.isAdmin);
      navigate("/");
    } catch (e) {
      navigate("/");
      console.log(e);
    }
  };

  return (
    <div className="login flex-3">
      {msgContext}
      <div className="login-box">
        <h2 className="title">请输入验证码</h2>
        <Input
          value={psd}
          onChange={(e) => {
            // console.log(e);
            let v = e.target.value;
            // console.log(v);
            setPwd(v.trim());
          }}
        />
        <div className="btn-group">
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
          <Button type="primary" onClick={submit}>
            确定
          </Button>
        </div>
      </div>
    </div>
  );
};
export default memo(Login);
