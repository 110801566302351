import axios from 'axios';
try {
    axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:80' : undefined;
} catch (e) {
    console.log(e);
}
// axios.defaults.baseURL = 'https://m.liaomzy.cn'

console.log(process.env.NODE_ENV);

// console.dir(axios);

axios.interceptors.response.use(result => {
        // console.log("响应拦截打印:")
        // console.log(result);
        return result.data
        //
    },
    err => {
        console.log(err);
        return Promise.reject
    })

// axios.interceptors.response((res)=>{},err)
export default axios;