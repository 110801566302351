import React, { memo, useEffect, useMemo, useState } from "react";

import { Button, Form, Input, message, Popconfirm, Table } from "antd";
import { reqCardList, reqDelByCardNo } from "../../api";
import { useGlobalStore } from "../../store";
import CardModal from "./components/CardModal";
import { useNavigate, Link } from "react-router-dom";
import { cardTypeMap } from "./constans";
import dayjs from "dayjs";
import "./index.scss";
import EditPsdModal from "./components/EditPsdModal";

const Manage = () => {
  const [cardList, setCardList] = useState([]);
  const [msgApi, msgContext] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [onceData, setOnceData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const useCode = useGlobalStore((state) => state.code);
  const isAdmin = useGlobalStore((store) => store.isAdmin);
  const navigate = useNavigate();
  useEffect(() => {
    if (!useCode) {
      navigate("/login");
      return;
    }
    if (!isAdmin) {
      navigate("/");
      return;
    }
    getCardList();
  }, [useCode, isAdmin]);

  const getCardList = async () => {
    try {
      setLoading(true);
      const res = await reqCardList({
        password: useCode,
      });
      const { code, data, message } = res;
      // console.log(res);
      if (code !== 200) {
        msgApi.error(message || "请求失败！");
        setCardList([]);
        return;
      }
      setCardList(data);
    } catch (e) {
      console.log(e);
      setCardList([]);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (data) => {
    getCardList(data);
  };

  const onDel = async (cardNo) => {
    try {
      setLoading(true);
      const res = await reqDelByCardNo({
        password: useCode,
        cardNo,
      });
      const { code, data, message } = res;

      if (code !== 200) {
        msgApi.error(message || "请求失败！");
        return;
      }
      const i = cardList.findIndex((v) => v.cardNo === cardNo);
      if (i > -1) {
        cardList.splice(i, 1);
        setCardList([...cardList]);
      }
      // console.log("cardList", cardList);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: "卡号",
        dataIndex: "cardNo",
      },
      {
        title: "类型",
        dataIndex: "cardType",
        render(type, record) {
          if (type === "vip") {
            return "永久";
          }
          return (record.num || "") + cardTypeMap[type];
        },
      },
      {
        title: "到期时间",
        dataIndex: "endTime",
        render(endTime, record) {
          if (record.type === "vip") {
            return "永久";
          }
          let d = dayjs(endTime).diff(dayjs());
          // console.log(d);
          if (d < 0) {
            return "已过期";
          }
          return dayjs(endTime).format("YYYY/MM/DD HH:mm:ss");
        },
      },
      {
        title: "操作",
        dataIndex: "cardNo",
        width: 120,
        render: (cardNo, record) => {
          return (
            <>
              <Button
                onClick={() => {
                  setOnceData(record);
                  setOpenCardModal(true);
                }}
                size="small"
                style={{ marginRight: 8 }}
              >
                编辑
              </Button>
              <Popconfirm
                title="删除卡号"
                description="即将删除卡号请确定"
                onConfirm={() => onDel(cardNo)}
              >
                <Button danger size="small">
                  删除
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];
  }, [cardList]);

  return (
    <div className="manage">
      {msgContext}
      <div className="manage-header">
        <Form onFinish={onFinish} layout="inline">
          <Form.Item name={"cardNo"} label="卡号">
            <Input style={{ width: 300 }} placeholder="请输入卡号" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </Form>
        <div className="btn-group flex-4">
          {isAdmin ? (
            <Link className="go-to-manage" to="/">
              去首页
            </Link>
          ) : null}
          <div className="right-btns">
            <Button
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              修改vip密码
            </Button>{" "}
            <Button
              type="primary"
              onClick={() => {
                setOpenCardModal(true);
                setOnceData(null);
              }}
            >
              新增
            </Button>{" "}
          </div>
        </div>
      </div>
      <div className="table-wrap">
        <Table
          loading={loading}
          columns={columns}
          dataSource={cardList}
          pagination={{
            size: 10,
          }}
          size="small"
          rowKey={"cardNo"}
        ></Table>
      </div>

      <CardModal
        open={openCardModal}
        onceData={onceData}
        onCancel={() => {
          setOpenCardModal(false);
        }}
        onOk={(newData) => {
          // console.log("newData", newData);
          const item = cardList.find((item) => newData.cardNo == item.cardNo);
          if (item) {
            item.cardType = newData.cardType;
            item.num = newData.num;
            setCardList([...cardList]);
            return;
          }
          setCardList([newData, ...cardList]);
        }}
        useCode={useCode}
      />
      <EditPsdModal
        open={openEditModal}
        useCode ={useCode}
        onCancel={() => {
          setOpenEditModal(false);
        }}
      />
    </div>
  );
};
export default memo(Manage);
