import Home from "../pages/home";
import Manage from "../pages/manage";
import Login from "../pages/login";

const router = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/manage",
    element: <Manage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
];

export default router;
