import axios from "./request";


export function reqList(data) {
    return axios.post("/api/fs/list", {
        "page": 1,
        "per_page": 0,
        "refresh": false,
        ...data
    })
}

export function reqGet(data) {
    return axios.post("/api/fs/get", data)
}
export function reqCardList(data) {
    return axios.post("/api/card-list", data)
}

export function reqAddOrUpdateCard(data) {
    return axios.post("/api/add-update-card", data)
}

export function reqUpdatePsd(data) {
    return axios.post("/api/update-password", data)
}

export function reqGetUserInfo(data) {
    return axios.post("/api/user-info", data)
}
export function reqDelByCardNo(data) {
    return axios.post("/api/del-card", data)
}