import { memo } from "react";
import AudioPlayer from "../../../../components/AudioPlayer";
import { MP3_TYPE, VIDEO_TYPE } from "../../../../constans";
import VideoPlayer from "../../../../components/VideoPlayer";
import Download from "../Download";

const FileDetails = ({ detailsInfo, fileType }) => {
  switch (fileType) {
    case MP3_TYPE:
      return <AudioPlayer detailsInfo={detailsInfo} />;
    case VIDEO_TYPE:
      return <VideoPlayer detailsInfo={detailsInfo} />;
    default:
      break;
  }

  return <Download fileType={fileType} detailsInfo={detailsInfo} />;
};

export default memo(FileDetails);
