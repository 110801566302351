import React, { memo, useEffect, useState } from "react";
import { Button, List, message, Select } from "antd";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import logoPng from "../../assets/images/logo.png";
import { reqGet, reqList } from "../../api";
import "./index.scss";
import Download from "./components/Download";
import AudioPlayer from "../../components/AudioPlayer";
import FileIcon from "../../components/FileIcon";
import CustomList from "./components/CustomList";
import VideoPlayer from "../../components/VideoPlayer";
import { useGlobalStore } from "../../store";
import { getFileTypeByName } from "../../constans";
import FileDetails from "./components/FileDetails";

const VIDEO_TYPE = ".mp4";
const PDF_TYPE = ".pdf";
const MP3_TYPE = ".mp3";
const fileType = [PDF_TYPE, VIDEO_TYPE, MP3_TYPE];

const Home = () => {
  const [search] = useSearchParams();
  const path = search.get("path");

  const navgate = useNavigate();
  const [navPath, setNavPath] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectVal, setSelectVal] = useState("preview");
  const [showType, setShowType] = useState("");
  const [msgApi, msgContext] = message.useMessage();
  const [detailsInfo, setDetailsInfo] = useState({});
  const isAdmin = useGlobalStore((store) => store.isAdmin);
  const useCode = useGlobalStore((store) => store.code);
  // const showTypeMap ={
  //    'list' ,'audio','viode'
  // }
  // FolderFilled
  useEffect(() => {
    if (!useCode) {
      navgate("/login");
      return;
    }
    if (path && path.length > 3) {
      const _type = getFileTypeByName(path);
      if (_type) {
        getInfo(path);
        setShowType(_type);
        return;
      }
    }
    getList(path);
    setShowType("");
  }, [path, useCode]);
  // console.log(path);

  const getList = async (path) => {
    try {
      setLoading(true);
      const res = await reqList({
        path: `/${path || ""}`,
        password: useCode,
      });
      const { code, data, message } = res;
      // console.log(res);
      if (code !== 200) {
        msgApi.error(message || "请求失败！");
        setNavPath([]);
        setFileList([]);
        return;
      }
      const { content } = data || {};
      setFileList(
        content.map((v) => {
          v._fileType = getFileTypeByName(v.name);
          return v;
        })
      );
      saveNav(path);
    } catch (e) {
      console.log(e);
      setNavPath([]);
      setFileList([]);
    } finally {
      setLoading(false);
    }
  };
  const saveNav = (path) => {
    if (path) {
      const pathList = path.split("/");
      setNavPath(pathList);
    } else {
      setNavPath([]);
    }
  };
  const getInfo = async (path) => {
    try {
      setLoading(true);
      const res = await reqGet({
        path: `/${path}`,
        password: useCode,
      });
      const { code, data, message } = res;
      saveNav(path);
      if (code !== 200) {
        msgApi.error(message || "请求失败！");
        setDetailsInfo({});
        return;
      }
      setDetailsInfo(data);
    } catch (e) {
      console.log(e);
      setDetailsInfo({});
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setSelectVal(value);
  };
  const handleJump = (nameOrIndex, type) => {
    if (nameOrIndex === "") {
      navgate("/");
      return;
    }
    let newNav = [];
    if (type === "sub") {
      newNav = navPath.slice(0, nameOrIndex + 1);
    } else {
      if (navPath.length) {
        if (showType) {
          // console.log("nameOrIndex", nameOrIndex, showType);
          // console.log("navPath", navPath);
          navPath[navPath.length - 1] = nameOrIndex;
          newNav = navPath;
        } else {
          newNav = [...navPath, nameOrIndex];
        }
      } else {
        newNav = [nameOrIndex];
      }
    }
    // console.log("newNav-last", newNav);
    navgate(`/?path=${newNav.join("/")}`);
  };

  const len = navPath.length;
  return (
    <div className="home">
      {msgContext}
      <img className="home-logo" src={logoPng} />
      {isAdmin ? (
        <Link className="go-to-manage" to="/manage">
          去管理页
        </Link>
      ) : null}
      <div className="home-by">
        <ul className="home-breadcrumb">
          <li
            className="item-breadcrumb"
            key={"first"}
            onClick={() => {
              handleJump("", "sub");
            }}
          >
            <span className="home-path">🏠主页</span>
            {len ? <span className="nav-separator">/</span> : null}
          </li>
          {navPath.map((name, i) => {
            return (
              <li
                className="item-breadcrumb"
                key={name}
                onClick={() => {
                  handleJump(i, "sub");
                }}
              >
                <span className="home-path"> {name}</span>
                {len > i + 1 ? <span className="nav-separator">/</span> : null}
              </li>
            );
          })}
        </ul>
        <div className="card">
          <Select
            value={selectVal}
            onChange={handleChange}
            options={[
              {
                value: "preview",
                label: "预览",
              },
              {
                value: "download",
                label: "下载",
              },
            ]}
          ></Select>
          <div className="content">
            {selectVal === "download" ? (
              <Download detailsInfo={detailsInfo} />
            ) : (
              <>
                {showType !== "" ? (
                  <FileDetails detailsInfo={detailsInfo} fileType={showType} />
                ) : null}
              </>
            )}
            <CustomList
              loading={loading}
              fileList={fileList}
              onJump={handleJump}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Home);
