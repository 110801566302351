import React, { memo, useEffect, useRef, useState } from "react";
 
import "aplayer-react/dist/index.css";
import "./index.scss";
import coverIcon from "../../assets/images/cover.jpg";

import "aplayer/dist/APlayer.min.css";
// import "./audio.css"
import APlayer from "aplayer";

const AudioPlayer = ({ detailsInfo }) => {
  // cover.jpg
  const auidoWrapRef = useRef(null);

  useEffect(() => {
    const { thumb, raw_url, name } = detailsInfo || {};
    const ap = new APlayer({
      container: auidoWrapRef.current,
      mini: false,
      autoplay: true,
      loop: "all",
      order: "list",
      preload: "auto",
      volume: 0.7,
      mutex: true,
      listFolded: false,

      audio: {
        name: name,
        // artist: "Vaundy",
        url: raw_url,
        cover: thumb || coverIcon,
      },
    });
    return () => {
      ap.destroy();
    };
  }, [detailsInfo]);

  return <div className="audio-wrap" ref={auidoWrapRef}></div>;

  //   作者：Doma
  //   链接：https://juejin.cn/post/7189882644568473658
  //   来源：稀土掘金
  //   著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。
};

export default memo(AudioPlayer);
