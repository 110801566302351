import { Form, message, Modal, Radio, InputNumber, Input } from "antd";

import React, { memo, useEffect, useState } from "react";
import { reqAddOrUpdateCard, reqUpdatePsd } from "../../../../api";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import "./index.scss";
const CardModal = (props) => {
  const { open, onCancel, onOk, onceData, useCode } = props;
  const [form] = Form.useForm();
  const [msgApi, msgContext] = message.useMessage();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open, onceData]);

  const onFinish = async (formData) => {
    try {
      // console.log(formData);
      setConfirmLoading(true);

      const postData = {
        password: useCode,
        ...formData,
      };
      const res = await reqUpdatePsd(postData);
      const { code, data, message } = res;

      if (code !== 200) {
        msgApi.error(message || "请求失败！");
        return;
      }

      msgApi.success("修改成功");
      onCancel();
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      title={"修改密码"}
      onCancel={onCancel}
      onOk={() => {
        // console.log("ok");
        form.submit();
      }}
      confirmLoading={confirmLoading}
    >
      {msgContext}
      {/* horizontal */}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          cardType: "d",
          num: 1,
        }}
        labelCol={{
          span: 8,
        }}
        labelWrap={{
          span: 8,
        }}
        className="card-form"
      >
        <Form.Item
          name={"newPassword"}
          label="密码"
          rules={[{ required: true }]}
        >
          <Input disabled={false} placeholder="请输入vip新密码" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default memo(CardModal);
