import React, { memo, useEffect, useRef } from "react";

// import MyPlugin from "my-xgplayer-plugin";
import Player from "xgplayer";
// import { I18N } from "xgplayer";
// import ZH from "xgplayer/es/lang/zh-cn";
import "xgplayer/dist/index.min.css"; // 引入西瓜视频样式

import "./index.scss";
// import ReactPlayer from 'react-player'
// 启用中文
// console.log("ZH", ZH);

// I18N.use(ZH);

function VideoPlayer(props) {
  //https://v2.h5player.bytedance.com/config/#%E5%BE%AE%E4%BF%A1%E5%85%A8%E5%B1%8F%E6%92%AD%E6%94%BE
  const { detailsInfo } = props;
  const videoRef = useRef(null);
  const videoInstance = useRef(null);
  useEffect(() => {
    if (detailsInfo) {
      const player = new Player({
        fluid: true,
        download: true, //设置download控件显示
        lang: "zh-cn",
        el: videoRef.current,
        url: detailsInfo.raw_url,
        poster: detailsInfo.thumb,
        width: "100%",
        height: "100%",
        volume: 0.6, // 初始音量
        autoplay: false, // 自动播放
        playbackRate: [0.5, 0.75, 1, 1.5, 2], // 当前播放速度
        defaultPlaybackRate: 1, // 播放速度设置为1
        playsinline: true,
        // fitVideoSize: "auto", //自适应屏幕的宽高
        rotateFullscreen: true,
        // eslint-disable-next-line no-dupe-keys
        fitVideoSize: "auto",
        videoInit: true, // 初始化显示视频首帧
        // 原文链接：https://blog.csdn.net/weixin_48305373/article/details/116044747
        "x5-video-orientation": "portraint", //微信横竖屏控制
      });

      return () => {
        player.destroy();
      };
    }
  }, [detailsInfo]);

  return <div ref={videoRef} className="video-wrap"></div>;
}

export default memo(VideoPlayer);
