const M = 1024 * 1024
export function toUnitM(size) {
    if (!size) {
        return '-'
    }
    try {
        size = size / M
        if (size > 1024) {
            size = size / 1024
            return size.toFixed(2) + ' GB'
        }
        return size.toFixed(2) + ' M'
    } catch (e) {
        console.log(e);

        return '-'
    }
}