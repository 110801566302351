export const VIDEO_TYPE = ".mp4";
export const PDF_TYPE = ".pdf";
export const MP3_TYPE = ".mp3";
export const WORD_TYPE = ".word";
export const TXT_TYPE = ".txt";
export const MD_TYPE = ".md";
export const ZIP_TYPE = ".zip";
export const UN_KNOWN_TYPE = "Unknown";
export const fileType = [PDF_TYPE, VIDEO_TYPE, MP3_TYPE, WORD_TYPE, TXT_TYPE, MD_TYPE, ZIP_TYPE];



export function getFileTypeByName(name) {
    if (!name) {
        return ''
    }
    const shortName = name.slice(-4);
    if (shortName.indexOf('.') == -1) {
        return ''
    }
    // shortName
    for (let index = 0; index < fileType.length; index++) {
        const type = fileType[index];
        if (shortName.indexOf(type) > -1) {
            return type;
        }
    }
    return ''
}