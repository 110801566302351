import {
    create
} from 'zustand';

export const useGlobalStore = create((set) => {
    return {
        code: "",
        isAdmin: false,
        changeCode: (code, isAdmin = false) => set(state => {
            return {
                code,
                isAdmin: isAdmin
            }
        })
    }
})