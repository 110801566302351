import React, { lazy } from "react";
import Home from "./pages/home";
import { RouterProvider, createHashRouter } from "react-router-dom";

import router from "./router";
import { ConfigProvider } from "antd";
import zhCN from 'antd/es/locale/zh_CN';
function App() {
  return (
    <React.StrictMode>
      <ConfigProvider locale={zhCN}>
        <RouterProvider router={createHashRouter(router)} />
      </ConfigProvider>
    </React.StrictMode>
  );
}

export default App;
