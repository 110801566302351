import { Button, message } from "antd";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { FolderFilled } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import "./index.scss";
import dayjs from "dayjs";
import { toUnitM } from "../../../../untils";
import FileIcon from "../../../../components/FileIcon";
import { getFileTypeByName } from "../../../../constans";
const Download = (props) => {
  const [msgApi, msgContext] = message.useMessage();
  const { detailsInfo, fileType } = props;
  // console.log(props);
  const { raw_url, name, modified, size } = detailsInfo || {};

  return (
    <div className="download-wrap">
      <FileIcon type={fileType || getFileTypeByName(name)} />
      {msgContext}
      <div
        className="hope-stack hope-c-dhzjXW hope-c-PJLV hope-c-
         PJLV-ikjnSaO-css"
      >
        <h2 className="file-name">{name}</h2>
        <p className="file-text ">
          {toUnitM(size)} ·
          {modified ? dayjs(modified).format("YYYY-MM-DD HH:mm:ss") : ""}
        </p>
      </div>
      <div
        className="hope-stack hope-c-dhzjXW hope-c-PJLV 
            hope-c-PJLV-ikjnSaO-css"
      >
        <div className="hope-btn-group">
          <Button
            onClick={() => {
              try {
                copy(raw_url);
                msgApi.success("复制成功!");
              } catch (e) {}
            }}
          >
            复制链接
          </Button>
          <Button>
            <Link to={raw_url} target="_blank">
              下载
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default memo(Download);
