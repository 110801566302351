import { List } from "antd";
import React, { memo } from "react";

import FileIcon from "../../../../components/FileIcon";
import { noop } from "antd/es/_util/warning";
import "./index.scss";
import { toUnitM } from "../../../../untils";
import dayjs from "dayjs";

// let now = dayjs() //返回当前时间
// now.format('YYYY-MM-DD')
// now.format('YYYY-MM-DD HH:mm:ss')
// now.format('YYYY年MM月DD日')
const CustomList = (props) => {
  const { fileList, onJump = noop, loading } = props;
  //   console.log("fileList", fileList);
  return (
    <List
      loading={loading}
      className="home-list"
      // pagination={{
      //   position,
      //   align,
      // }}
      dataSource={fileList}
      renderItem={(item, index) => (
        <List.Item key={item.name}>
          <div
            className="left flex-2 text-over"
            onClick={() => {
              onJump(item.name);
            }}
          >
            <FileIcon type={item._fileType} />
            <span className="text-over">{item.name}</span>
          </div>
          <span className="size">{toUnitM(item.size)}</span>
          <span className="modified">
            {item.modified
              ? dayjs(item.modified).format("YYYY-MM-DD HH:mm:ss")
              : null}
          </span>
        </List.Item>
      )}
    />
  );
};
export default memo(CustomList);
