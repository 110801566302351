import { Form, message, Modal, Radio, InputNumber, Input } from "antd";

import React, { memo, useEffect, useState } from "react";
import { reqAddOrUpdateCard } from "../../../../api";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import './index.scss'
const CardModal = (props) => {
  const { open, onCancel, onOk, onceData, useCode } = props;
  const [form] = Form.useForm();
  const [msgApi, msgContext] = message.useMessage();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (onceData) {
        form.setFieldsValue({
          ...onceData,
        });
      } else {
        form.resetFields();
      }
    }
  }, [open, onceData]);

  const onFinish = async (formData) => {
    try {
      // console.log(formData);
      setConfirmLoading(true);
      const now = dayjs(onceData ? onceData.startTime : undefined);

      let endTime = 0;
      if (formData.cardType == "d") {
        endTime = now.add(formData.num, "d").valueOf();
      } else if (formData.cardType === "m") {
        endTime = now.add(formData.num * 30, "d").valueOf();
      }
      const postData = {
        password: useCode,
        ...formData,
        startTime: now.valueOf(),
        endTime: endTime,
      };
      const res = await reqAddOrUpdateCard(postData);
      const { code, data, message } = res;

      if (code !== 200) {
        msgApi.error(message || "请求失败！");
        return;
      }
      if (copy(data)) {
        msgApi.success("复制成功");
        onCancel();
        onOk({
          ...postData,
          cardNo: data,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      title={onceData ? "修改卡密" : "新增卡密"}
      onCancel={onCancel}
      onOk={() => {
        // console.log("ok");
        form.submit();
      }}
      confirmLoading={confirmLoading}
    >
      {msgContext}
      {/* horizontal */}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          cardType: "d",
          num: 1,
        }}
        labelCol={{
          span: 8,
        }}
        labelWrap={{
          span: 8,
        }}
        className="card-form"
      >
        <Form.Item name={"cardNo"} label="卡号">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="类型" name={"cardType"}>
          <Radio.Group>
            <Radio value="m"> 月</Radio>
            <Radio value="d"> 天</Radio>
            <Radio value="vip">永久</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={"num"} label="有效期">
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default memo(CardModal);
