import React, { memo } from "react";
import {
  FileUnknownFilled,
  FileFilled,
  FileImageFilled,
  FilePdfFilled,
  FileZipFilled,
  FileWordFilled,
  FileTextFilled,
  FileMarkdownFilled,
  AudioFilled,
  VideoCameraFilled,
  FolderFilled,
} from "@ant-design/icons";
import {
  MD_TYPE,
  MP3_TYPE,
  PDF_TYPE,
  TXT_TYPE,
  VIDEO_TYPE,
  UN_KNOWN_TYPE,
  ZIP_TYPE,
  WORD_TYPE,
} from "../../constans";
const fileIconMap = {
  file: <FileFilled />,
  image: <FileImageFilled />,
  [VIDEO_TYPE]: <VideoCameraFilled />,
  [MP3_TYPE]: <AudioFilled />,
  [TXT_TYPE]: <FileTextFilled />,
  [MD_TYPE]: <FileMarkdownFilled />,
  [PDF_TYPE]: <FilePdfFilled />,
  [ZIP_TYPE]: <FileZipFilled />,
  [UN_KNOWN_TYPE]: <FileUnknownFilled />,
  [WORD_TYPE]: <FileWordFilled />,
};
const FileIcon = ({ type }) => {
  return fileIconMap[type] || <FolderFilled />;
};

export default memo(FileIcon);
